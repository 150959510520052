export default class PredictionsPostModel {
    constructor(item) {
        this._setFirstCompetitorName(item);
        this._setFirstCompetitorLogo(item);
        this._setFirstCompetitorOdd(item);
        this._setFirstCompetitorUrl(item);
        this._setFirstCompetitorScore(item);
        this._setFirstCompetitorPredictionScore(item);
        this._setSecondCompetitorName(item);
        this._setSecondCompetitorLogo(item);
        this._setSecondCompetitorOdd(item);
        this._setSecondCompetitorUrl(item);
        this._setSecondCompetitorScore(item);
        this._setSecondCompetitorPredictionScore(item);
        this._setPredictionTypeText(item);
        this._setPredictionTypeText_ru(item);
        this._setPredictionResultText(item);
        this._setPredictionResultText_ru(item);
        this._setStartDate(item);
        this._setPublishTime(item);
        this._setGameId(item);
        this._setTournamentId(item);

        this._setTitle(item);
        this._setIframe(item);
        this._setTitle_ru(item);
        this._setDescription(item);
        this._setDescription_ru(item);
        this._setCategoryId(item);
        this._setMatchId(item);
        this._setKeyPoints(item);
    }


    _setFirstCompetitorName({firstName}) {
        this.firstCompetitorName = firstName;
    }

    _setFirstCompetitorLogo({firstLogo}) {
        this.firstCompetitorLogoUrl = firstLogo;
    }

    _setFirstCompetitorOdd({firstOdd}) {
        this.firstCompetitorOdd = firstOdd;
    }
    _setFirstCompetitorUrl({firstUrl}) {
        this.firstCompetitorUrl = firstUrl ?? '';
    }
    _setFirstCompetitorScore({firstScore}) {
        this.firstCompetitorScore = firstScore ?? '';
    }
    _setFirstCompetitorPredictionScore({firstPredictionScore}) {
        this.firstCompetitorPredictionScore = firstPredictionScore ?? '';
    }

    _setSecondCompetitorName({secondName}) {
        this.secondCompetitorName = secondName;
    }

    _setSecondCompetitorLogo({secondLogo}) {
        this.secondCompetitorLogoUrl = secondLogo;
    }

    _setSecondCompetitorOdd({secondOdd}) {
        this.secondCompetitorOdd = secondOdd;
    }

    _setSecondCompetitorUrl({secondUrl}) {
        this.secondCompetitorUrl = secondUrl ?? '';
    }

    _setSecondCompetitorScore({secondScore}) {
        this.secondCompetitorScore = secondScore ?? '';
    }
    _setSecondCompetitorPredictionScore({secondPredictionScore}) {
        this.secondCompetitorPredictionScore = secondPredictionScore??'';
    }

    _setPredictionTypeText({typeText}) {
        this.predictionTypeText = typeText;
    }

    _setPredictionTypeText_ru({typeText_ru}) {
        this.predictionTypeText_ru = typeText_ru;
    }

    _setPredictionResultText({resultText}) {
        this.predictionResultText = resultText;
    }

    _setPredictionResultText_ru({resultText_ru}) {
        this.predictionResultText_ru = resultText_ru;
    }


    _setStartDate(item) {
        this.startDate = item.date + 'T' + item.time;
    }

     _setPublishTime(item) {
        this.publisTime = item.publishDate + 'T' + item.publishTime;
    }

    _setGameId({gameId}) {
        this.gameId = gameId;
    }

    _setTournamentId({tournamentId}) {
        this.tournamentId = tournamentId;
    }

    //categoryId

    _setCategoryId({categoryId}) {
        this.categoryId = categoryId;
    }

    //match id

    _setMatchId({matchId}) {
        this.matchId = matchId;
    }

    //title and description


    _setTitle({title}) {
        this.title = title;
    }
    _setIframe({iframe}) {
        this.iframe = iframe;
    }

    _setTitle_ru({title_ru}) {
        this.title_ru = title_ru;
    }

    _setDescription({description}) {
        this.description = description;
    }
    _setDescription_ru({description_ru}) {
        this.description_ru = description_ru;
    }
    _setKeyPoints({keyPoints}) {
        this.keyPoints = keyPoints;
    }
}
